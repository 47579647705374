import React, { useState, useEffect } from 'react';
import CardDisplay from '../../components/CardDisplay';
import { getUserCard } from '../../controllers/CardController';

function CardPage() {
  const [card, setCard] = useState(null);

  useEffect(() => {
    async function fetchCard() {
      const userCard = await getUserCard();
      setCard(userCard);
    }
    fetchCard();
  }, []);

  if (!card) return <div>Loading...</div>;

  return (
    <div>
      <h1>Your Starbucks Card</h1>
      <CardDisplay card={card} />
    </div>
  );
}

export default CardPage;
