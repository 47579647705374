import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllProducts, deleteProduct } from '../../controllers/productController'; // นำเข้าฟังก์ชันสำหรับดึงและลบข้อมูลสินค้า
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function ProductListPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false); // สำหรับจัดการการเปิด Dialog ยืนยันการลบ
  const [selectedProduct, setSelectedProduct] = useState(null); // เก็บข้อมูลสินค้าที่จะถูกลบ

  // ดึงข้อมูลสินค้าจาก API เมื่อหน้าโหลด
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getAllProducts();

        // แปลงข้อมูลที่ได้จาก API โดยไม่เปลี่ยนชื่อฟิลด์
        const formattedData = data.map((product) => ({
          ...product, // คงฟิลด์เดิมทั้งหมด
          id: product.productID, // เพิ่มฟิลด์ id โดยใช้ productID เพื่อใช้กับ DataGrid
          imageUrl: `${process.env.REACT_APP_API_URL}${product.imageUrl}`, // เติม base URL เพื่อให้แสดงผลรูปภาพได้
        }));

        setProducts(formattedData);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // ฟังก์ชันสำหรับลบสินค้า
  const handleDeleteProduct = async () => {
    try {
      if (selectedProduct) {
        await deleteProduct(selectedProduct.id); // ลบสินค้าตาม ID
        // อัปเดตข้อมูลในตาราง
        setProducts(products.filter((product) => product.id !== selectedProduct.id));
        setSelectedProduct(null);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // ฟังก์ชันเปิด dialog ยืนยันการลบ
  const handleOpenDialog = (product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  // ฟังก์ชันปิด dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  // กำหนดคอลัมน์ของตาราง โดยใช้ฟิลด์ตาม API
  const columns = [
    { field: 'id', headerName: 'ลำดับ', width: 100 }, // ใช้ฟิลด์ id ที่แปลงมาจาก productID
    {
      field: 'imageUrl',
      headerName: 'รูปภาพ',
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value || '/no-image.png'} // ใช้ภาพ default หากไม่มี URL ของรูปภาพ
          alt="product"
          style={{ width: '100%', height: 'auto' }}
        />
      ),
    },
    { field: 'productName', headerName: 'ชื่อสินค้า', width: 150 },
    { field: 'code1', headerName: 'รหัส 1', width: 150 },
    { field: 'code2', headerName: 'รหัส 2', width: 150 },
    { field: 'description', headerName: 'รายละเอียด', flex: 1 },
    { field: 'price', headerName: 'ราคา', width: 120 }, // แสดงราคาพร้อมหน่วย
    { field: 'stock', headerName: 'จำนวนสินค้า', width: 120 },
    {
      field: 'createdAt',
      headerName: 'วันที่สร้าง',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'การจัดการ',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            component={Link}
            to={`/admin/products/${params.row.id}`}
          >
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleOpenDialog(params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          กำลังโหลดรายการสินค้า...
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        รายการสินค้า
      </Typography>

      {/* แสดงตาราง DataGrid */}
      <div style={{ height: 500, width: '100%', overflowX: 'auto' }}>
        <DataGrid
          rows={products} // ส่งข้อมูลที่ถูกต้องโดยใช้ฟิลด์ id จาก productID
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>

      {/* ปุ่มเพิ่มสินค้า */}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/admin/products/new"
        style={{ marginTop: '20px' }}
      >
        เพิ่มสินค้าใหม่
      </Button>

      {/* Dialog สำหรับยืนยันการลบ */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ยืนยันการลบสินค้า"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            คุณต้องการลบสินค้านี้หรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            ยกเลิก
          </Button>
          <Button onClick={handleDeleteProduct} color="secondary" autoFocus>
            ลบสินค้า
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ProductListPage;
