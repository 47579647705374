import React, { useState } from 'react';
import { TextField, Button, Container } from '@mui/material';
import { makePayment } from '../controllers/PaymentController';

function PaymentForm({ cardId }) {
  const [amount, setAmount] = useState('');

  const handleSubmit = async () => {
    try {
      await makePayment({ cardId, amount });
      alert('Payment Successful');
    } catch (error) {
      alert('Payment Failed');
    }
  };

  return (
    <Container>
      <TextField
        label="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Make Payment
      </Button>
    </Container>
  );
}

export default PaymentForm;
