// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminRoutes from './routes/AdminRoutes';
import LineLiftRoutes from './routes/LineLiftRoutes';
import Header from './layouts/Header';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* รวมเส้นทางของ Admin */}
        <Route path="/*" element={<AdminRoutes />} />

        {/* รวมเส้นทางของ Line LIFT */}
        <Route path="/line-lift/*" element={<LineLiftRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;
