import React from 'react';
import PaymentForm from '../../components/PaymentForm';

function PaymentPage() {
  const cardId = '123456789';  // ตัวอย่าง Card ID
  return (
    <div>
      <h1>Make a Payment</h1>
      <PaymentForm cardId={cardId} />
    </div>
  );
}

export default PaymentPage;
