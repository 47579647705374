import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RegisterPage from '../pages/line-lift/RegisterPage';
import CardPage from '../pages/line-lift/CardPage';
import PaymentPage from '../pages/line-lift/PaymentPage';
import CardDetailsPage from '../pages/line-lift/CardDetailsPage';

function LineLiftRoutes() {
  return (
    <Routes>
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/card" element={<CardPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/card-details" element={<CardDetailsPage />} />
    </Routes>
  );
}

export default LineLiftRoutes;
