import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

function CardDisplay({ card }) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">Card Number: {card.number}</Typography>
            <Typography variant="h6">Balance: ${card.balance}</Typography>
          </Grid>
          <Grid item xs={6}>
            <img src={card.image} alt="Card" style={{ width: '100%' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CardDisplay;
