import React, { useEffect, useState } from 'react';
import CardDetails from '../../components/CardDetails';
import { getCardDetails } from '../../controllers/CardController';

function CardDetailsPage() {
  const [card, setCard] = useState(null);

  useEffect(() => {
    async function fetchCardDetails() {
      const cardData = await getCardDetails();
      setCard(cardData);
    }
    fetchCardDetails();
  }, []);

  if (!card) return <div>Loading...</div>;

  return <CardDetails card={card} />;
}

export default CardDetailsPage;
