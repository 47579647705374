// src/stores/actions/memberActions.js
import axios from 'axios';

export const fetchMembers = () => async (dispatch) => {
  dispatch({ type: 'FETCH_MEMBERS_REQUEST' });
  try {
    const response = await axios.get('/api/members'); // API call ไปยัง backend เพื่อดึงข้อมูลสมาชิก
    dispatch({ type: 'FETCH_MEMBERS_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_MEMBERS_FAILURE', payload: error.message });
  }
};
