export const getUserCard = async () => {
    try {
      const response = await fetch('/api/card');
      return await response.json();
    } catch (error) {
      console.error('Error fetching card:', error);
      throw error;
    }
  };
  
  export const getCardDetails = async () => {
    try {
      const response = await fetch('/api/card/details');
      return await response.json();
    } catch (error) {
      console.error('Error fetching card details:', error);
      throw error;
    }
  }