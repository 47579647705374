import React from 'react';
import { Typography, Container } from '@mui/material';

function CardDetails({ card }) {
  return (
    <Container>
      <Typography variant="h6">Card Number: {card.number}</Typography>
      <Typography variant="body1">Balance: ${card.balance}</Typography>
      <Typography variant="body1">Expiry Date: {card.expiry}</Typography>
    </Container>
  );
}

export default CardDetails;
