import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Button, CircularProgress, Input } from '@mui/material';
import { getProductById, addProduct, updateProduct, deleteProduct } from '../../controllers/productController'; // นำเข้า controller
import { uploadFile } from '../../controllers/FileController'; // นำเข้า controller ที่เราสร้างใหม่สำหรับการอัปโหลดไฟล์

const ProductDetailPage = () => {
  const { productId } = useParams(); // รับค่า productId จาก URL
  const navigate = useNavigate(); // สำหรับการเปลี่ยนเส้นทางเมื่อทำการบันทึกเสร็จสิ้น
  const [product, setProduct] = useState({
    productName: '',
    code1: '',
    code2: '',
    description: '',
    imageUrl: '',
    price: 0,
    stock: 0,
  });
  const [imageFile, setImageFile] = useState(null); // สำหรับการเก็บไฟล์รูปภาพ
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(!!productId); // ถ้ามี productId แสดงว่าเป็นโหมดแก้ไข

  useEffect(() => {
    if (productId) {
      const fetchProduct = async () => {
        try {
          const data = await getProductById(productId);
          setProduct(data);
        } catch (err) {
          setError('Failed to load product details.');
        } finally {
          setLoading(false);
        }
      };
      fetchProduct();
    } else {
      setLoading(false); // ถ้าไม่มี productId แสดงว่าเป็นการเพิ่มสินค้าใหม่
    }
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]); // เก็บไฟล์ที่ผู้ใช้เลือกใน state
  };

  const handleSave = async () => {
    try {
      let imageUrl = product.imageUrl;

      if (imageFile) {
        // ถ้ามีไฟล์รูปใหม่ ให้อัปโหลดและได้ URL กลับมา
        imageUrl = await uploadFile(imageFile);
      }

      const updatedProduct = {
        ...product,
        imageUrl,
      };

      if (isEditMode) {
        await updateProduct(productId, updatedProduct);
      } else {
        await addProduct(updatedProduct);
      }

      navigate('/admin/products'); // กลับไปที่หน้ารายการสินค้าเมื่อบันทึกสำเร็จ
    } catch (err) {
      setError('Failed to save product.');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(productId);
      navigate('/admin/products'); // กลับไปที่หน้ารายการสินค้าหลังจากลบ
    } catch (err) {
      setError('Failed to delete product.');
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {isEditMode ? 'แก้ไขสินค้า' : 'เพิ่มสินค้าใหม่'}
      </Typography>

      <TextField
        label="ชื่อสินค้า"
        name="productName"
        value={product.productName}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="รหัส 1"
        name="code1"
        value={product.code1}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="รหัส 2"
        name="code2"
        value={product.code2}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="รายละเอียด"
        name="description"
        value={product.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        label="ราคา"
        name="price"
        type="number"
        value={product.price}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="สต็อก"
        name="stock"
        type="number"
        value={product.stock}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />

      {/* ฟิลด์สำหรับอัปโหลดรูป */}
      <Input type="file" onChange={handleFileChange} fullWidth />

      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
          {isEditMode ? 'บันทึก' : 'เพิ่มสินค้า'}
        </Button>

        {isEditMode && (
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            ลบสินค้า
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ProductDetailPage;
